/* You can add global styles to this file, and also import other style files */
/* @import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:regular,bold,italic&subset=latin,latin-ext'); */

/* Add this CSS to your styles.css file or wherever you're managing your global styles */

html,
body {
  background-color: #f1f0e8;
  min-height: 100%;
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  scroll-behavior: smooth;

  @media (prefers-reduced-motion: reduce) {
    scroll-behavior: auto;
  }
}

body {
  display: flex;
  flex-direction: column;
}

#root {
  height: 100%;
  background: #f1f0e8;
}

/* used for survey buttons */
.sd-btn--action {
  background-color: #3d7f53 !important;
}

.sd-page * {
  font-family: 'Poppins' !important;
}

.sd-body,
.sd-body--static {
  margin: 0 !important;
  max-width: none !important;
}

.sd-element__num * {
  font-size: 18px !important;
}

.sd-item__decorator,
.sd-boolean {
  border: solid 2px #7f898f !important;
}

.sd-boolean__thumb {
  background: #3d7f53 !important;
  color: #fff !important;
  font-weight: 400 !important;
}

.sd-radio--checked .sd-item__decorator {
  border: none !important;
}

@font-face {
  font-family: 'Poppins';
  src: url('./fonts/Poppins/Poppins-Regular.ttf') format('truetype');
  font-weight: 400;
}

@font-face {
  font-family: 'Poppins';
  src: url('./fonts/Poppins/Poppins-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins';
  src: url('./fonts/Poppins/Poppins-SemiBold.ttf') format('truetype');
  font-weight: 600;
}

@font-face {
  font-family: 'Poppins';
  src: url('./fonts/Poppins/Poppins-Bold.ttf') format('truetype');
  font-weight: 700;
}

@font-face {
  font-family: 'Lora';
  src: url('./fonts/Lora/static/Lora-Regular.ttf') format('truetype');
  font-weight: 400;
}

@font-face {
  font-family: 'Lora';
  src: url('./fonts/Lora/static/Lora-Medium.ttf') format('truetype');
  font-weight: 500;
}

@font-face {
  font-family: 'Lora';
  src: url('./fonts/Lora/static/Lora-Bold.ttf') format('truetype');
  font-weight: 600;
}
